<template>
  <div
    class="relative"
    style="min-height: calc(100vh - 60px); margin-top: 60px"
  >
    <main-menu class="z-30"> </main-menu>
    <div v-if="news !== null">
      <div
        class="relative flex justify-center bg-fixed bg-cover bg-center bg-no-repeat"
        style="min-height: 250px"
      >
        <div
          class="flex justify-center items-center h-full w-full absolute text-center bg-green-500 py-6 px-8 bg-opacity-90"
        >
          <h1 class="text-white">
            <span class="font-bold text-2xl md:text-3xl">
              {{ news.title }}
            </span>
            <span class="block text-normal">
              {{ news.updated_at | indonesian_datetime_format }}
            </span>
          </h1>
        </div>
      </div>

      <div class="w-full">
        <img :src="news.image_url_full" alt="gambar" />
      </div>

      <div
        class="mb-8 py-10 prose bg-white relative lg:prose-xl mx-2 md:mx-auto px-6 md:px-10 pb-36"
      >
        <div id="news-content" class=""></div>
      </div>
    </div>
    <div v-else class="pt-32 text-center text-2xl">Berita Tidak Ditemukan</div>
  </div>
</template>

<script>
import axios from "axios";
import marked from "marked";
import DOMPurify from "dompurify";

export default {
  data() {
    return {
      news: {},
    };
  },
  methods: {
    fetchNews() {
      axios
        .get(`api/news/${this.$route.params.id}`)
        .then((response) => {
          this.news = response.data.news;
          let content = DOMPurify.sanitize(marked(this.news.content));
          this.$nextTick(() => {
            document.getElementById("news-content").innerHTML = content;
          });
        })
        .catch((error) => {
          this.news = null;
          let httpStatus =
            error.response !== undefined ? error.response.status : 500;

          let message =
            httpStatus === 404
              ? "Konten berita tidak ditemukan"
              : "Konten berita tidak bisa diakses";

          this.$toasted.error(message);
        });
    },
  },
  mounted() {
    this.fetchNews();
  },
  components: {
    MainMenu: () => import("@/components/MainMenu.vue"),
  },
  name: "NewsReader",
};
</script>

<style lang="scss" scoped>
.title__overlay {
  background-image: linear-gradient(to bottom, #10b98100, #10b981cc);

  @apply absolute h-full w-full top-0 left-0;
}
</style>